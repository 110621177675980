import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _7b2313d6 = () => interopDefault(import('../src/pages/contribute.vue' /* webpackChunkName: "pages/contribute" */))
const _d5f027d2 = () => interopDefault(import('../src/pages/cookies.vue' /* webpackChunkName: "pages/cookies" */))
const _daeee162 = () => interopDefault(import('../src/pages/cookies-en.vue' /* webpackChunkName: "pages/cookies-en" */))
const _fe1c564c = () => interopDefault(import('../src/pages/index.vue' /* webpackChunkName: "pages/index" */))
const _a1d00a7e = () => interopDefault(import('../src/pages/events.vue' /* webpackChunkName: "pages/events" */))
const _a0739db0 = () => interopDefault(import('../src/pages/internal/index.vue' /* webpackChunkName: "pages/internal/index" */))
const _455be436 = () => interopDefault(import('../src/pages/marketold/index.vue' /* webpackChunkName: "pages/marketold/index" */))
const _544ca890 = () => interopDefault(import('../src/pages/privacy.vue' /* webpackChunkName: "pages/privacy" */))
const _9b1d5794 = () => interopDefault(import('../src/pages/privacy-en.vue' /* webpackChunkName: "pages/privacy-en" */))
const _ba83b8fa = () => interopDefault(import('../src/pages/refunds.vue' /* webpackChunkName: "pages/refunds" */))
const _3a441063 = () => interopDefault(import('../src/pages/refunds-en.vue' /* webpackChunkName: "pages/refunds-en" */))
const _2aef930f = () => interopDefault(import('../src/pages/terms.vue' /* webpackChunkName: "pages/terms" */))
const _2b27e557 = () => interopDefault(import('../src/pages/terms-en.vue' /* webpackChunkName: "pages/terms-en" */))
const _680596c6 = () => interopDefault(import('../src/pages/thankyou.vue' /* webpackChunkName: "pages/thankyou" */))
const _9edf2080 = () => interopDefault(import('../src/pages/u/index.vue' /* webpackChunkName: "pages/u/index" */))
const _7772566f = () => interopDefault(import('../src/pages/where.vue' /* webpackChunkName: "pages/where" */))
const _5acf2833 = () => interopDefault(import('../src/pages/internal/events.vue' /* webpackChunkName: "pages/internal/events" */))
const _9f417d0e = () => interopDefault(import('../src/pages/internal/home.vue' /* webpackChunkName: "pages/internal/home" */))
const _1d8f05b8 = () => interopDefault(import('../src/pages/internal/list.vue' /* webpackChunkName: "pages/internal/list" */))
const _796dd43f = () => interopDefault(import('../src/pages/internal/login.vue' /* webpackChunkName: "pages/internal/login" */))
const _2f98d770 = () => interopDefault(import('../src/pages/internal/membership.vue' /* webpackChunkName: "pages/internal/membership" */))
const _bb6083a0 = () => interopDefault(import('../src/pages/internal/news/index.vue' /* webpackChunkName: "pages/internal/news/index" */))
const _71d89e52 = () => interopDefault(import('../src/pages/internal/ticketcheck.vue' /* webpackChunkName: "pages/internal/ticketcheck" */))
const _387be130 = () => interopDefault(import('../src/pages/internal/validate.vue' /* webpackChunkName: "pages/internal/validate" */))
const _3ab4398a = () => interopDefault(import('../src/pages/marketold/calendar.vue' /* webpackChunkName: "pages/marketold/calendar" */))
const _65f3a039 = () => interopDefault(import('../src/pages/marketold/contribute.vue' /* webpackChunkName: "pages/marketold/contribute" */))
const _0f7a73f3 = () => interopDefault(import('../src/pages/u/forgot-password.vue' /* webpackChunkName: "pages/u/forgot-password" */))
const _7a3812d7 = () => interopDefault(import('../src/pages/u/login.vue' /* webpackChunkName: "pages/u/login" */))
const _677f1a32 = () => interopDefault(import('../src/pages/u/manage.vue' /* webpackChunkName: "pages/u/manage" */))
const _26341050 = () => interopDefault(import('../src/pages/u/membership.vue' /* webpackChunkName: "pages/u/membership" */))
const _fcacfab6 = () => interopDefault(import('../src/pages/u/register.vue' /* webpackChunkName: "pages/u/register" */))
const _a5498c38 = () => interopDefault(import('../src/pages/u/confirm/activate.vue' /* webpackChunkName: "pages/u/confirm/activate" */))
const _f31f0778 = () => interopDefault(import('../src/pages/u/confirm/expired.vue' /* webpackChunkName: "pages/u/confirm/expired" */))
const _405b7622 = () => interopDefault(import('../src/pages/u/confirm/success.vue' /* webpackChunkName: "pages/u/confirm/success" */))
const _635da957 = () => interopDefault(import('../src/pages/u/confirm/trigger.vue' /* webpackChunkName: "pages/u/confirm/trigger" */))
const _2f084480 = () => interopDefault(import('../src/pages/u/recover/activate.vue' /* webpackChunkName: "pages/u/recover/activate" */))
const _1cfdece8 = () => interopDefault(import('../src/pages/u/recover/expired.vue' /* webpackChunkName: "pages/u/recover/expired" */))
const _522e3274 = () => interopDefault(import('../src/pages/u/recover/success.vue' /* webpackChunkName: "pages/u/recover/success" */))
const _2ba412de = () => interopDefault(import('../src/pages/internal/event/_id/index.vue' /* webpackChunkName: "pages/internal/event/_id/index" */))
const _cbd0e6d0 = () => interopDefault(import('../src/pages/internal/news/_id.vue' /* webpackChunkName: "pages/internal/news/_id" */))
const _4f1f4a6a = () => interopDefault(import('../src/pages/internal/view/_id.vue' /* webpackChunkName: "pages/internal/view/_id" */))
const _23a920ad = () => interopDefault(import('../src/pages/u/confirm/_confirmId.vue' /* webpackChunkName: "pages/u/confirm/_confirmId" */))
const _19303d89 = () => interopDefault(import('../src/pages/u/recover/_confirmId.vue' /* webpackChunkName: "pages/u/recover/_confirmId" */))
const _13db3421 = () => interopDefault(import('../src/pages/internal/event/_id/lists.vue' /* webpackChunkName: "pages/internal/event/_id/lists" */))
const _4891274d = () => interopDefault(import('../src/pages/event/_id.vue' /* webpackChunkName: "pages/event/_id" */))
const _6d9ae813 = () => interopDefault(import('../src/pages/internal/_404.vue' /* webpackChunkName: "pages/internal/_404" */))
const _011418b4 = () => interopDefault(import('../src/pages/news/_id.vue' /* webpackChunkName: "pages/news/_id" */))
const _1c7b130a = () => interopDefault(import('../src/pages/u/_404.vue' /* webpackChunkName: "pages/u/_404" */))
const _5119f3be = () => interopDefault(import('../src/pages/_404.vue' /* webpackChunkName: "pages/_404" */))
const _3744d472 = () => interopDefault(import('../src/pages/_oldindex.vue' /* webpackChunkName: "pages/_oldindex" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/contribute",
    component: _7b2313d6,
    name: "contribute___it"
  }, {
    path: "/cookies",
    component: _d5f027d2,
    name: "cookies___it"
  }, {
    path: "/cookies-en",
    component: _daeee162,
    name: "cookies-en___it"
  }, {
    path: "/en",
    component: _fe1c564c,
    name: "index___en"
  }, {
    path: "/events",
    component: _a1d00a7e,
    name: "events___it"
  }, {
    path: "/internal",
    component: _a0739db0,
    name: "internal___it"
  }, {
    path: "/marketold",
    component: _455be436,
    name: "marketold___it"
  }, {
    path: "/privacy",
    component: _544ca890,
    name: "privacy___it"
  }, {
    path: "/privacy-en",
    component: _9b1d5794,
    name: "privacy-en___it"
  }, {
    path: "/refunds",
    component: _ba83b8fa,
    name: "refunds___it"
  }, {
    path: "/refunds-en",
    component: _3a441063,
    name: "refunds-en___it"
  }, {
    path: "/terms",
    component: _2aef930f,
    name: "terms___it"
  }, {
    path: "/terms-en",
    component: _2b27e557,
    name: "terms-en___it"
  }, {
    path: "/thankyou",
    component: _680596c6,
    name: "thankyou___it"
  }, {
    path: "/u",
    component: _9edf2080,
    name: "u___it"
  }, {
    path: "/where",
    component: _7772566f,
    name: "where___it"
  }, {
    path: "/en/contribute",
    component: _7b2313d6,
    name: "contribute___en"
  }, {
    path: "/en/cookies",
    component: _d5f027d2,
    name: "cookies___en"
  }, {
    path: "/en/cookies-en",
    component: _daeee162,
    name: "cookies-en___en"
  }, {
    path: "/en/events",
    component: _a1d00a7e,
    name: "events___en"
  }, {
    path: "/en/internal",
    component: _a0739db0,
    name: "internal___en"
  }, {
    path: "/en/marketold",
    component: _455be436,
    name: "marketold___en"
  }, {
    path: "/en/privacy",
    component: _544ca890,
    name: "privacy___en"
  }, {
    path: "/en/privacy-en",
    component: _9b1d5794,
    name: "privacy-en___en"
  }, {
    path: "/en/refunds",
    component: _ba83b8fa,
    name: "refunds___en"
  }, {
    path: "/en/refunds-en",
    component: _3a441063,
    name: "refunds-en___en"
  }, {
    path: "/en/terms",
    component: _2aef930f,
    name: "terms___en"
  }, {
    path: "/en/terms-en",
    component: _2b27e557,
    name: "terms-en___en"
  }, {
    path: "/en/thankyou",
    component: _680596c6,
    name: "thankyou___en"
  }, {
    path: "/en/u",
    component: _9edf2080,
    name: "u___en"
  }, {
    path: "/en/where",
    component: _7772566f,
    name: "where___en"
  }, {
    path: "/internal/events",
    component: _5acf2833,
    name: "internal-events___it"
  }, {
    path: "/internal/home",
    component: _9f417d0e,
    name: "internal-home___it"
  }, {
    path: "/internal/list",
    component: _1d8f05b8,
    name: "internal-list___it"
  }, {
    path: "/internal/login",
    component: _796dd43f,
    name: "internal-login___it"
  }, {
    path: "/internal/membership",
    component: _2f98d770,
    name: "internal-membership___it"
  }, {
    path: "/internal/news",
    component: _bb6083a0,
    name: "internal-news___it"
  }, {
    path: "/internal/ticketcheck",
    component: _71d89e52,
    name: "internal-ticketcheck___it"
  }, {
    path: "/internal/validate",
    component: _387be130,
    name: "internal-validate___it"
  }, {
    path: "/marketold/calendar",
    component: _3ab4398a,
    name: "marketold-calendar___it"
  }, {
    path: "/marketold/contribute",
    component: _65f3a039,
    name: "marketold-contribute___it"
  }, {
    path: "/u/forgot-password",
    component: _0f7a73f3,
    name: "u-forgot-password___it"
  }, {
    path: "/u/login",
    component: _7a3812d7,
    name: "u-login___it"
  }, {
    path: "/u/manage",
    component: _677f1a32,
    name: "u-manage___it"
  }, {
    path: "/u/membership",
    component: _26341050,
    name: "u-membership___it"
  }, {
    path: "/u/register",
    component: _fcacfab6,
    name: "u-register___it"
  }, {
    path: "/en/internal/events",
    component: _5acf2833,
    name: "internal-events___en"
  }, {
    path: "/en/internal/home",
    component: _9f417d0e,
    name: "internal-home___en"
  }, {
    path: "/en/internal/list",
    component: _1d8f05b8,
    name: "internal-list___en"
  }, {
    path: "/en/internal/login",
    component: _796dd43f,
    name: "internal-login___en"
  }, {
    path: "/en/internal/membership",
    component: _2f98d770,
    name: "internal-membership___en"
  }, {
    path: "/en/internal/news",
    component: _bb6083a0,
    name: "internal-news___en"
  }, {
    path: "/en/internal/ticketcheck",
    component: _71d89e52,
    name: "internal-ticketcheck___en"
  }, {
    path: "/en/internal/validate",
    component: _387be130,
    name: "internal-validate___en"
  }, {
    path: "/en/marketold/calendar",
    component: _3ab4398a,
    name: "marketold-calendar___en"
  }, {
    path: "/en/marketold/contribute",
    component: _65f3a039,
    name: "marketold-contribute___en"
  }, {
    path: "/en/u/forgot-password",
    component: _0f7a73f3,
    name: "u-forgot-password___en"
  }, {
    path: "/en/u/login",
    component: _7a3812d7,
    name: "u-login___en"
  }, {
    path: "/en/u/manage",
    component: _677f1a32,
    name: "u-manage___en"
  }, {
    path: "/en/u/membership",
    component: _26341050,
    name: "u-membership___en"
  }, {
    path: "/en/u/register",
    component: _fcacfab6,
    name: "u-register___en"
  }, {
    path: "/u/confirm/activate",
    component: _a5498c38,
    name: "u-confirm-activate___it"
  }, {
    path: "/u/confirm/expired",
    component: _f31f0778,
    name: "u-confirm-expired___it"
  }, {
    path: "/u/confirm/success",
    component: _405b7622,
    name: "u-confirm-success___it"
  }, {
    path: "/u/confirm/trigger",
    component: _635da957,
    name: "u-confirm-trigger___it"
  }, {
    path: "/u/recover/activate",
    component: _2f084480,
    name: "u-recover-activate___it"
  }, {
    path: "/u/recover/expired",
    component: _1cfdece8,
    name: "u-recover-expired___it"
  }, {
    path: "/u/recover/success",
    component: _522e3274,
    name: "u-recover-success___it"
  }, {
    path: "/en/u/confirm/activate",
    component: _a5498c38,
    name: "u-confirm-activate___en"
  }, {
    path: "/en/u/confirm/expired",
    component: _f31f0778,
    name: "u-confirm-expired___en"
  }, {
    path: "/en/u/confirm/success",
    component: _405b7622,
    name: "u-confirm-success___en"
  }, {
    path: "/en/u/confirm/trigger",
    component: _635da957,
    name: "u-confirm-trigger___en"
  }, {
    path: "/en/u/recover/activate",
    component: _2f084480,
    name: "u-recover-activate___en"
  }, {
    path: "/en/u/recover/expired",
    component: _1cfdece8,
    name: "u-recover-expired___en"
  }, {
    path: "/en/u/recover/success",
    component: _522e3274,
    name: "u-recover-success___en"
  }, {
    path: "/",
    component: _fe1c564c,
    name: "index___it"
  }, {
    path: "/en/internal/event/:id",
    component: _2ba412de,
    name: "internal-event-id___en"
  }, {
    path: "/en/internal/news/:id",
    component: _cbd0e6d0,
    name: "internal-news-id___en"
  }, {
    path: "/en/internal/view/:id?",
    component: _4f1f4a6a,
    name: "internal-view-id___en"
  }, {
    path: "/en/u/confirm/:confirmId?",
    component: _23a920ad,
    name: "u-confirm-confirmId___en"
  }, {
    path: "/en/u/recover/:confirmId?",
    component: _19303d89,
    name: "u-recover-confirmId___en"
  }, {
    path: "/en/internal/event/:id?/lists",
    component: _13db3421,
    name: "internal-event-id-lists___en"
  }, {
    path: "/en/event/:id?",
    component: _4891274d,
    name: "event-id___en"
  }, {
    path: "/en/internal/:404",
    component: _6d9ae813,
    name: "internal-404___en"
  }, {
    path: "/en/news/:id?",
    component: _011418b4,
    name: "news-id___en"
  }, {
    path: "/en/u/:404",
    component: _1c7b130a,
    name: "u-404___en"
  }, {
    path: "/internal/event/:id",
    component: _2ba412de,
    name: "internal-event-id___it"
  }, {
    path: "/internal/news/:id",
    component: _cbd0e6d0,
    name: "internal-news-id___it"
  }, {
    path: "/internal/view/:id?",
    component: _4f1f4a6a,
    name: "internal-view-id___it"
  }, {
    path: "/u/confirm/:confirmId?",
    component: _23a920ad,
    name: "u-confirm-confirmId___it"
  }, {
    path: "/u/recover/:confirmId?",
    component: _19303d89,
    name: "u-recover-confirmId___it"
  }, {
    path: "/internal/event/:id?/lists",
    component: _13db3421,
    name: "internal-event-id-lists___it"
  }, {
    path: "/en/:404",
    component: _5119f3be,
    name: "404___en"
  }, {
    path: "/en/:oldindex",
    component: _3744d472,
    name: "oldindex___en"
  }, {
    path: "/event/:id?",
    component: _4891274d,
    name: "event-id___it"
  }, {
    path: "/internal/:404",
    component: _6d9ae813,
    name: "internal-404___it"
  }, {
    path: "/news/:id?",
    component: _011418b4,
    name: "news-id___it"
  }, {
    path: "/u/:404",
    component: _1c7b130a,
    name: "u-404___it"
  }, {
    path: "/:404",
    component: _5119f3be,
    name: "404___it"
  }, {
    path: "/:oldindex",
    component: _3744d472,
    name: "oldindex___it"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
